import { Injectable } from '@angular/core';
import { CanDeactivate, Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import swal from 'sweetalert2';

import { EditProductComponent } from './edit/edit-product.component';

@Injectable()
export class ProductGuard implements CanDeactivate<EditProductComponent> {

    canDeactivate(component: EditProductComponent) {
        if(component.unsavedChanges) {

            /*swal({
              title: "Confirma operação?",
              text: "Após exclusão, a série ficará indisponível!",
              type: 'warning',
              showConfirmButton: true,
              showCancelButton: true
            })
            .then((willDelete) => {

              if (willDelete.value)
                return true;
              else
                return false;

            });*/

            return window.confirm('Tem certeza que deseja abandonar o preenchimento do formulario?');
        }
        return true;
    }

}
