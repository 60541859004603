import { userLogin } from 'src/app/models/userlogin';
import { role } from 'src/app/models/Enum/role';
import { Injectable } from '@angular/core';
import { tokenNotExpired } from 'angular2-jwt';
import { HttpRequest } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {
  cachedRequests: Array<HttpRequest<any>> = [];

  public getToken(): string
  {
    return localStorage.getItem("token");
  }

  public collectFailedRequest(request): void {
      this.cachedRequests.push(request);
  }

  public isAuthenticated(): boolean {
    return tokenNotExpired(null, this.getToken());
  }

  isAuthorized() {
    return (localStorage.getItem("UserLogin") != '');
  }

  hasRole(userRole: role) {
    return (Number(this.LoginToken().Role) === userRole);
  }

  logout() {
    localStorage.removeItem("UserLogin");
  }

  name() {
    return this.LoginToken().Name;
  }

  roleName() {
    return this.LoginToken().RoleName;
  }

  roleId() {
    return Number(this.LoginToken().Role);
  }

  customerId() {
    return Number(this.LoginToken().CustomerId);
  }

  email() {
    return this.LoginToken().Email;
  }

  userId() {
    return this.LoginToken().Id;
  }

  public LoginToken(): userLogin {
    try {
      return jwt_decode(this.getToken());
    } catch (Error) {
      return null;
    }
  }
}
