import { RecoverComponent } from './pages/recover/recover.component';
import { LayoutComponent } from '../layout/layout.component';

import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from '../auth/auth.guard';
import { AuthGuardGestor } from '../auth/auth.guard.gestor';

export const routes = [
    { path: '', component: LoginComponent},
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
            { path: 'useraccount', loadChildren: () => import('./general/useraccount/useraccount.module').then(m => m.UserAccountModule)},

            /*SIG*/
            { path: 'discipline', loadChildren: () => import('./general/discipline/discipline.module').then(m => m.DisciplineModule), canActivate: [AuthGuardGestor]},
            { path: 'test', loadChildren: () => import('./general/test/test.module').then(m => m.TestModule), canActivate: [AuthGuardGestor]},
            { path: 'coordinator', loadChildren: () => import('./general/coordinator/coordinator.module').then(m => m.CoordinatorModule), canActivate: [AuthGuardGestor]},
            { path: 'book', loadChildren: () => import('./general/book/book.module').then(m => m.BookModule), canActivate: [AuthGuardGestor]},
            { path: 'calendar', loadChildren: () => import('./general/calendar/calendar.module').then(m => m.CalendarModule), canActivate: [AuthGuardGestor]},
            { path: 'frequencySig', loadChildren: () => import('./general/frequencySig/frequencySig.module').then(m => m.FrequencySigModule), canActivate: [AuthGuardGestor]},
            { path: 'paceRecordsSig', loadChildren: () => import('./general/pacerecordsSig/paceRecordSig.mudule').then(m => m.PaceRecordsSigModule), canActivate: [AuthGuardGestor]},
            { path: 'testRecordsSig', loadChildren: () => import('./general/testRecordsSig/testRecordSig.mudule').then(m => m.TestRecordsSigModule), canActivate: [AuthGuardGestor]},
            { path: 'frequencyStudentSig', loadChildren: () => import('./general/frequencyStudentsSig/frequencyStudentSig.module').then(m => m.FrequencyStudentSigModule), canActivate: [AuthGuardGestor]},
            { path: 'testStudentRecordsSig', loadChildren: () => import('./general/testRecordStudentsSig/testRecordStudentSig.mudule').then(m => m.TestRecordsStudentSigModule), canActivate: [AuthGuardGestor]},
            { path: 'school_size', loadChildren: () => import('./configuration/school_size/school_size.module').then(m => m.School_sizeModule), canActivate: [AuthGuardGestor]},
            { path: 'school_type', loadChildren: () => import('./configuration/school_type/school_type.module').then(m => m.School_typeModule), canActivate: [AuthGuardGestor]},
            { path: 'position', loadChildren: () => import('./configuration/position/position.module').then(m => m.PositionModule), canActivate: [AuthGuardGestor]},
            { path: 'customer_type', loadChildren: () => import('./configuration/customer_type/customer_type.module').then(m => m.Customer_typeModule), canActivate: [AuthGuardGestor]},
            { path: 'book_follow_type', loadChildren: () => import('./configuration/book_follow_type/book_follow_type.module').then(m => m.Book_follow_typeModule), canActivate: [AuthGuardGestor]},
            { path: 'program_type', loadChildren: () => import('./configuration/program_type/program_type.module').then(m => m.Program_typeModule), canActivate: [AuthGuardGestor]},
            { path: 'shift', loadChildren: () => import('./configuration/shift/shift.module').then(m => m.ShiftModule), canActivate: [AuthGuardGestor]},
            { path: 'calendar_process', loadChildren: () => import('./configuration/calendar_process/calendar_process.module').then(m => m.Calendar_processModule), canActivate: [AuthGuardGestor]},
            { path: 'calendar_status', loadChildren: () => import('./configuration/calendar_status/calendar_status.module').then(m => m.Calendar_statusModule), canActivate: [AuthGuardGestor]},
            { path: 'month_reference', loadChildren: () => import('./configuration/month_reference/month_reference.module').then(m => m.Month_referenceModule), canActivate: [AuthGuardGestor]},
            { path: 'studentsSig', loadChildren: () => import('./general/studentUserSig/studentUserSig.module').then(m => m.StudentUserSigModule), canActivate: [AuthGuardGestor]},
            { path: 'parametersSig', loadChildren: () => import('./configuration/parametersSig/parametersSig.module').then(m => m.ParametersSigModule), canActivate: [AuthGuardGestor]},
            { path: 'school_days', loadChildren: () => import('./configuration/school_days/school_days.module').then(m => m.School_daysModule), canActivate: [AuthGuardGestor]},
            { path: 'customerSig', loadChildren: () => import('./configuration/customer/customerSig.module').then(m => m.CustomerSigModule), canActivate: [AuthGuardGestor]},
            { path: 'program', loadChildren: () => import('./general/program/program.module').then(m => m.ProgramModule), canActivate: [AuthGuardGestor]},
            { path: 'schoolSig', loadChildren: () => import('./configuration/school/schoolSig.module').then(m => m.SchoolSigModule), canActivate: [AuthGuardGestor]},
            { path: 'userSig', loadChildren: () => import('./general/userSig/userSig.module').then(m => m.UserSigModule), canActivate: [AuthGuardGestor]},
            { path: 'profile', loadChildren: () => import('./configuration/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuardGestor]},


            /*Relatórios - SIG*/
            { path: 'reportSig', loadChildren: () => import('./reports/report.module').then(m => m.ReportModule), canActivate: [AuthGuardGestor] },
            { path: 'exportCsv', loadChildren: () => import('./general/exportCsv/exportCsv.module').then(m => m.ExportCsvModule), canActivate: [AuthGuardGestor] },
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
    { path: 'recover', component: RecoverComponent },

    // Not found
    { path: '**', redirectTo: 'home' }

];
