import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from '../../../core/settings/settings.service';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { userLogin } from 'src/app/models/userlogin';
import { SecurityService } from 'src/app/services/security.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ApiStatusService } from 'src/app/services/apiStatus.service';
import swal from 'sweetalert2';
import { MenssageType } from 'src/app/models/Enum/messageType';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    valForm: FormGroup;
    apiAvailable: boolean = true;
    showPassword: boolean = false;

    constructor(
        public fb: FormBuilder, 
        private router: Router, 
        private renderer: Renderer2, 
        public settings: SettingsService, 
        private securityService: SecurityService, 
        private authService: AuthService, 
        private apiStatusService: ApiStatusService
    ) {
        this.valForm = fb.group({
            'email': ["", Validators.compose([Validators.required, CustomValidators.email])],
            'password': ["", Validators.required]
        });
    }

    ngOnInit() {
        window.sessionStorage.removeItem('token');
        this.apiStatusService.checkApiStatus().subscribe((status: boolean) => {
            this.apiAvailable = status;
            if (!status) {
                //this.processMensage({ messageType: MenssageType.Alert, message: 'O sistema está em manutenção. Por favor, tente mais tarde.' });
                this.valForm.disable(); 
            }
        });
    }  

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            this.ValidateAccess(this.valForm.value.email, this.valForm.value.password);
        }
    }

    ValidateAccess(email: string, password: string) {
        this.securityService.AuthenticateUser({username: email, password: password, logintype: 'SIG' }).subscribe((data: any) => {
            if (data.registers == null) {
                this.processMensage({ messageType: MenssageType.Warning, message: 'Usuário ou senha incorretos!' });
            } else {
                localStorage.setItem("token", data.registers.token);
                this.router.navigateByUrl('/home');
            }
        },
        error => {
            this.processMensage({messageType: MenssageType.Error, message: 'Erro ao autenticar o acesso do usuário.'});
        });
    }

    processMensage(mensageData: any) {
        if (mensageData.messageType == MenssageType.Success) {
            swal('Sucesso!', mensageData.message, 'success');
        } else if (mensageData.messageType == MenssageType.Warning) {
            swal('Atenção!', mensageData.message, 'warning');
        } else if (mensageData.messageType == MenssageType.Alert) {
            swal('Atenção!', mensageData.message, 'info');
        } else {
            swal('Ocorreu um erro!', mensageData.message, 'error');
        }
    }

    togglePasswordVisibility(): void {
      this.showPassword = !this.showPassword;
  }
}
