import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { role } from '../models/Enum/role';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private util:AuthService, private router: Router){}

  canActivate(){
    console.log("AuthGuard canActivate called");
    if (!this.util.isAuthenticated()) {
      this.router.navigate(['login']);
    }
    return this.util.hasRole(role.Administrador);    
  }

  canActivateChild(){
    return this.util.hasRole(role.Administrador);
  }
  
  canLoad() {
    return this.util.hasRole(role.Administrador);
  }
}
