export enum role {
    Administrador = 1,
    CoordenadorIAB = 3,
    GestorEscolar = 6,
    SupervisorMunicipal = 7,
    SecretarioMunicipalEducacao=11,    
    CoordenadorPedagogico=17,    
    CoordenadorModoLeitura=18,
    DirecaoIAB = 25    
  }
